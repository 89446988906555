<template lang="html">
  <div>
    <p>
      <a href="https://coreui.io/vue/demo/3.2.2/#/dashboard" target="_blanck">
        Core UI PROD
      </a>
      <br />
      <a href="https://coreui.io/vue/docs/introduction/" target="_blanck">
        Doc online
      </a>
    </p>
  </div>
</template>

<script>
//
//import magentoSynchroListSites from "./ListSites.vue";
export default {
  name: "Document",
  props: {
    //
  },
  components: {
    //
  },
  data() {
    return {
      //
    };
  },
  mounted() {
    //
  },
  watch: {
    //
  },
  computed: {
    //
  },
  methods: {
    //
  },
};
</script>

<style lang="scss" scoped></style>

<!--
 //nom du fichier en pascal.
 //<template>
 - le nom des attributs en kebab-case;
 - la valeur des attributs et des variables en camelCase;
 - function en PascalCase
 //props, data
 - variable en camelCase
 //methods
 - variable en PascalCase
-->
